* {
  color: #fff;
}
.container {
  position: relative;
}
.content {
  position: relative;
  margin-top: 35%;
}
@media (min-width: 576px) {
  /* Phones*/
  .content {
    position: relative;
    margin-top: 35%;
  }
  .container {
    position: relative;
  }
}

@media (min-width: 768px) {
  /* tablets*/
  .content {
    position: relative;
    margin-top: 5%;
  }
  .container {
    position: relative;
  }
}

@media (min-width: 900px) {
  /* Laptops*/
  .content {
    position: relative;
    margin-top: 45%;
  }
  .container {
    position: relative;
  }
}
@media (min-width: 1200px) {
  /* Laptops*/
  .content {
    position: relative;
    margin-top: 25%;
  }
  .container {
    position: relative;
  }
}

@media (min-width: 1400px) {
  /* desktop*/
  .content {
    position: relative;
    margin-top: 0%;
  }
  .container {
    position: absolute;
    left: -300px;
  }
}
