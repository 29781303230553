@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;700&family=Lobster+Two:ital@1&family=Noto+Serif+Lao:wght@700&family=Roboto+Serif:opsz,wght@8..144,100;8..144,200;8..144,300&family=Roboto:wght@500&family=Rubik:wght@400;500;700&display=swap");
* {
  font-family: Roboto;
}
.line {
  width: 20%;
  height: 4px;
  background-color: #672078;
  margin: 0 auto;
}
.App {
  text-align: center;
}
#tab {
  color: #b22ad3 !important;
}
#tab:hover {
  color: #672078 !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #181818;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #181818;
}
.App-link {
  color: #61dafb;
}
