.expPage {
  margin-top: 5%;
}
@media (min-width: 576px) {
  /* Phones*/
  .expPage {
    margin-top: 5%;
  }
}

@media (min-width: 768px) {
  /* tablets*/
  .expPage {
    margin-top: 0%;
  }
}
@media (min-width: 900px) {
  /* Laptops*/
  .expPage {
    margin-top: 40%;
  }
}

@media (min-width: 1200px) {
  /* Laptops*/
  .expPage {
    margin-top: 40%;
  }
}

@media (min-width: 1400px) {
  /* desktop*/
  .expPage {
    margin-top: 0%;
  }
}
