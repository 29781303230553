span {
  color: white;
  font-size: 3em !important;
}

nav {
  background-color: #121212;
  border-bottom: 4px solid; /* Adjust the border color as needed */
  --angle: 0deg;
  border-image: linear-gradient(var(--angle), #12c2e9, #c471ed, #672078) 1;
  animation: 10s rotate linear infinite;
}
