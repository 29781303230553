* {
  color: #fff;
}
#aboutMe {
  background-color: #121212;
  padding: 15px;
  width: 100%;

  line-height: 2;
  border: 4px solid;
  border-radius: 10% !important;
  --angle: 0deg;

  border-image: linear-gradient(var(--angle), #12c2e9, #c471ed, #672078) 1;
  animation: 10s rotate linear infinite;
}
.seperator {
  color: #c471ed;
}
@media (min-width: 576px) {
  /* Phones*/
  #aboutMe {
    width: 100%;
  }
}

@media (min-width: 768px) {
  /* tablets*/
  #aboutMe {
    width: 70%;
  }
}

@media (min-width: 1200px) {
  /* Laptops*/
  #aboutMe {
    width: 60%;
  }
}

@media (min-width: 1400px) {
  /* desktop*/
  #aboutMe {
    width: 50%;
  }
}
