.selectorList {
  padding: 1em;
}
.unselected {
  text-indent: 0;
  width: 100%;
  height: 100%;
  border: 0px;
  opacity: 1;
  background-color: transparent;
  box-shadow: 0 0 0px 0px black;
  border-bottom: 5px solid #fff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selected {
  text-indent: 0;
  width: 100%;
  height: 100%;
  border: 0px;
  opacity: 1;
  background-color: transparent;
  box-shadow: 0 0 0px 0px black;
  border-bottom: 7px solid #12c2e9;
  color: #12c2e9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 1.1em;
}
.material-symbols-outlined {
  color: #fff;
}
.highlight {
  color: #12c2e9;
}
@media (min-width: 576px) {
  /* Phones*/
  .unselected {
    width: 100%;
  }
  .selected {
    width: 100%;
  }
}

@media (min-width: 768px) {
  /* tablets*/
  .unselected {
    width: 100%;
  }
  .selected {
    width: 100%;
  }
}
@media (min-width: 900px) {
  /* Laptops*/
  .unselected {
    width: 80%;
  }
  .selected {
    width: 80%;
  }
}
@media (min-width: 1200px) {
  /* Laptops*/
  .unselected {
    width: 80%;
  }
  .selected {
    width: 80%;
  }
}
@media (min-width: 1300px) {
  /* Laptops*/
  .unselected {
    width: 120%;
  }
  .selected {
    width: 120%;
  }
}

@media (min-width: 1400px) {
  /* desktop*/
  .unselected {
    width: 150%;
  }
  .selected {
    width: 150%;
  }
}
