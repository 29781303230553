.msg {
  color: gray;
  font-style: italic;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
li {
  list-style: none;
}
.test {
  color: #672078;
  font-size: 1.8em !important;
}
.eduPage {
  margin-top: 5%;
}
@media (min-width: 576px) {
  /* Phones*/
  .eduPage {
    margin-top: 5%;
  }
}

@media (min-width: 768px) {
  /* tablets*/
  .eduPage {
    margin-top: 0%;
  }
}
@media (min-width: 900px) {
  /* Laptops*/
  .eduPage {
    margin-top: 60%;
  }
}

@media (min-width: 1200px) {
  /* Laptops*/
  .eduPage {
    margin-top: 30%;
  }
}

@media (min-width: 1400px) {
  /* desktop*/
  .eduPage {
    margin-top: 0%;
  }
}
