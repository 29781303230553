#startBTN {
  color: #b22ad3;
  border-width: 5px;
  height: 3em;
  width: 7em;
  font-size: 1em;
  padding-top: 5px;
  --angle: 0deg;
  border-image: linear-gradient(var(--angle), #12c2e9, #c471ed, #672078) 1;
  animation: 10s rotate linear infinite;
}

#startBTN:hover {
  opacity: 1;
  border-radius: 2px;
  box-shadow: 0 0 1px 3px #b22ad3;
  color: #672078;
  font-size: 1.1em;
  border-image: linear-gradient(0deg, #12c2e9, #c471ed, #672078) 1;
}
#career {
  font-size: 25px;
}
@media (min-width: 576px) {
  /* Phones*/
  #startBTN {
    padding-top: 5px;
  }
  #career {
    font-size: 25px;
  }
}

@media (min-width: 768px) {
  /* tablets*/
  #career {
    font-size: 40px;
  }
  #startBTN {
    padding-top: 15px;
  }
}

@media (min-width: 1200px) {
  /* Laptops*/
  #startBTN {
    padding-top: 15px;
  }
  #career {
    font-size: 45px;
  }
}

@media (min-width: 1400px) {
  /* desktop*/
  #startBTN {
    padding-top: 15px;
  }
  #career {
    font-size: 50px;
  }
}
